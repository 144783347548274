@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

@mixin short {
    @media only screen and (max-device-width: 389px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        @content;
    }
}

@mixin smdesktop {
    @media only screen and (min-width: 1334px) and (max-width: 1728px) {
        @content;
    }
}

@mixin tinydesktop {
    @media only screen and (min-width: 1334px) and (max-width: 1428px) {
        @content;
    }
}

@mixin shortdesktop {
    @media (min-height: 320px) and (max-height: 955px) and (min-width: 700px) {
        @content;
    }
}
