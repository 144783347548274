@import "../../../../theme/mixins.scss";

#Logo {
    font-weight: 400;
    @include short() {
        padding: 10px 10px 5px;
    }
    &.desktop {
        padding-top: 0;
    }
    .welcome {
        padding-bottom: 10px;
    }
    .store_logo {
        max-width: 310px;
        text-align: center;
        margin: 15px auto 10px;
        @include short() {
            margin: 0 auto 8px;
            width: 160px;
        }
    }
}
