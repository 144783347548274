@import "../../../../theme/mixins.scss";

.vehicle {
    transition: all 0.7s ease-in;
    .item-photo {
        margin: 0;
        height: 28vh;
        width: 100%;
        overflow: hidden;
        @include short() {
            height: 19vh;
        }
        img {
            border: 1px solid var(--ion-color-light);
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    &.tablet .item-photo {
        height: 35vh;
    }

    &.desktop .item-photo {
        height: 34vh;
    }

    &.explanded {
        max-height: 89vh;
        .mobile.vehicle-detail .description {
            height: 44.7vh;
        }
        .vehicle-detail {
            top: 0;
            padding: 20px;
            margin: 0;
            .odo {
                margin-right: 40px;
            }
            .description {
                max-height: 400px;
                overflow-y: auto;
            }
            .deets span.spec {
                padding-top: 10px;
                .label {
                    margin-bottom: 5px;
                    &.spc {
                        width: 115px;
                        display: inline-block;
                    }
                }
            }
        }
    }

    .vehicle-detail {
        padding: 15px 18px;
        position: relative;
        top: -32px;
        margin: 0 10px -32px;

        &.tablet {
            margin: 0 60px -32px;
        }

        &.desktop {
            h1.specs {
                font-size: 1.6em;
            }
            span.spec {
                font-size: 1em;
                &.ids {
                    margin-bottom: 20px;
                }
                .label {
                    font-size: 0.8em;
                    font-weight: bold;
                }
            }
            h4.price {
                font-size: 1.7em;
                strong {
                    font-size: 30px;
                }
            }
        }

        h1.specs {
            color: var(--ion-color-primary);
            font-size: 1.2em;
            margin: 0 0 8px;
            .sub {
                display: block;
                font-size: 0.8em;
                color: var(--ion-color-light-contrast);
            }
        }

        span.spec {
            font-size: 0.8em;
            font-weight: 300;
            margin-bottom: 18px;
            &.ids {
                align-self: baseline;
            }
        }

        h4.price {
            text-align: right;
            font-size: 1.3em;
            margin: 0 0 2px;
            strong {
                font-family: var(--theme-bold-font);
                font-size: 24px;
            }
            .sub {
                display: block;
                font-size: 0.5em;
                color: var(--ion-color-light-contrast);
                &.lnk:hover {
                    color: var(--ion-color-secondary);
                }
                &.plus {
                    font-size: 0.6em;
                    &:hover {
                        color: var(--ion-color-light-contrast);
                    }
                }
            }
        }

        .see-more {
            font-size: 0.9em;
            text-align: left;
            position: absolute;
            bottom: 12px;
            .toggle {
                color: var(--ion-color-primary);
                cursor: pointer;
                font-size: 0.9em;
                span {
                    position: relative;
                    bottom: 2px;
                }
                &:hover {
                    font-weight: bold;
                }
            }
        }

        .call-price {
            font-weight: 300;
            font-size: 0.8em;
            color: var(--ion-color-primary);
            text-decoration: none;
            margin-bottom: 30px;
        }
    }

    .inv-error {
        text-align: center;
        padding: 20px;
        position: relative;
        ion-icon {
            position: absolute;
            left: 16px;
            top: 16px;
            color: #777;
        }
    }

    &.desktop {
        .item-photo {
            margin: 0;
            img {
                border-radius: 10px;
            }
        }
        .photo-tiles {
            display: flex;
            flex-direction: column;
            gap: 10px;
            img {
                width: 200px;
            }
        }
        .vehicle-detail {
            top: 0;
            background: none;
            margin: 0;
        }
    }
}
