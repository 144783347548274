@import "../../theme/mixins.scss";

.ion-page {
    overflow: hidden;
}

#Vinfo {
    height: 100%;
    font-family: var(--theme-font);

    &.desktop {
        .vinfo-desktop {
            background-color: var(--ion-color-step-100);
            .overlay,
            .overlay-img {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
            }
            .overlay {
                background-color: var(--ion-background-color);
                -webkit-clip-path: polygon(0% 38%, 0% 127%, 536% -11%);
                clip-path: polygon(0% 38%, 0% 127%, 536% -11%);
            }
            .overlay-img {
                background-color: var(--ion-background-color);
                opacity: 0.8;
            }
            #Logo {
                .store_logo {
                    min-width: 210px;
                }
            }
            .socials {
                padding: 0 0 14px;
                font-size: 1.6em;
            }
            .vehicle-photos {
                width: 630px;
                min-height: 471px;
                @include shortdesktop() {
                    min-height: 270px;
                    height: 40vh;
                }
            }
            .content {
                .flex-col {
                    align-self: center;
                }
                .flex-col.title {
                    width: 100%;
                }
                .flex-col.sections {
                    width: 35vw;
                    min-width: 550px;
                    max-width: 600px;
                    .vinfo-divider {
                        margin-bottom: 12px;
                    }
                    .contact-btns {
                        padding: 32px 26px;
                        @include shortdesktop() {
                            padding: 10px 26px;
                        }
                    }
                }
            }
        }
    }

    .content {
        width: 100%;
        &.gutters {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: var(--theme-content-width);
            margin: 0 auto;
            position: relative;
            &.vh {
                height: 80vh;
            }
        }
        &.fill {
            height: 100%;
        }
    }

    .bulletdivider {
        margin: 0 10px;
    }
}

#MobileDealerSocials.socials {
    margin-top: 15px;
}
