#MobileHeader {
    ion-img.store_logo {
        max-width: 250px;
        padding: 8px;
        align-self: flex-start;
        &::part(image) {
            max-height: 55px;
            object-fit: contain;
        }
    }
    .dealer-cta {
        padding: 5px;
        position: relative;
        top: 3px;
        ion-icon {
            margin-right: 10px;
        }
    }
}

.tablet #MobileHeader .dealer-cta {
    ion-icon {
        margin-right: 15px;
    }
}
