@import "../../../../theme/mixins.scss";

#Documents {
    &.tablet {
        &.block.simple {
            padding: 20px 60px 0 !important;
        }
    }

    &.desktop #DocView .block,
    &.tablet #DocView .block {
        height: 95px;
    }

    .view-more {
        text-align: center;
        margin-top: 15px;
        cursor: pointer;
        span {
            font-size: 0.8em;
            &:hover {
                color: var(--ion-color-primary);
            }
        }
    }
}

#DocModal {
    .doc-title {
        padding-left: 25px;
        font-family: var(--theme-bold-font);
    }
    &.mobile {
        .doc-title {
            text-align: center;
            padding-left: 0;
        }
        #DocView.list {
            .doc-list {
                .doc-icon {
                    bottom: 15px;
                    .report {
                        top: 17px;
                        width: 155%;
                    }
                }
            }
        }
    }
}

#DocView {
    &.documents-view.tablet {
        width: 90vw;
        margin: 25px auto;
    }
    &.list {
        overflow-y: auto;
        height: calc(100% - 60px);
        .doc-list {
            display: block;
            height: auto;
            padding: 0 20px 20px;
            .block {
                height: 120px;
                max-width: 100%;
                margin-bottom: 19px;
                flex-direction: row;
                align-items: center;
                text-align: left;
                &:last-child {
                    margin-bottom: 0;
                }
                .doc-icon {
                    position: relative;
                    max-width: 119px;
                    bottom: 34px;
                    padding: 18px;
                    .report {
                        width: 112%;
                        left: -2px;
                        top: 32px;
                    }
                }
                .doc-name {
                    font-size: 1.2em;
                    width: 100%;
                    text-align: left;
                    margin-left: 15px;
                    .sub {
                        display: block;
                        font-size: 0.8em;
                        font-weight: 100;
                    }
                }
                .doc-cta {
                    width: 264px;
                    padding: 15px 15px 5px 0;
                    .block {
                        margin-bottom: 10px;
                        height: 40px;
                        justify-content: center;
                    }
                    .single {
                        margin-top: 27px;
                    }
                }
                .doc-data {
                    width: 90%;
                }
            }
        }
    }
    .block {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        height: 62px;
        max-width: 173px;
        @include short() {
            height: 45px;
        }
        .doc-icon {
            display: block;
            margin: 0 auto;
            width: 26%;
            max-width: 65px;
            height: 42px;
            color: white;
            .report {
                width: 300%;
                position: relative;
                top: 12px;
                right: 100%;
                margin: 0 auto;
            }
        }
        .doc-name {
            font-size: 0.8em;
            width: 100%;
            text-align: center;
            align-self: center;
        }
    }
}

#DocOptions {
    padding: 20px 10px 10px;
    .block {
        height: 100px;
    }
}
