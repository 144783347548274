@import "../../../../theme/mixins.scss";

#DealerTile {
    padding: 10px;
    width: 44%;
    height: 72px;
    align-self: center;
}

#DealerModal {
    #Dealership.desktop .item-photo {
        border-radius: 10px 10px 0 0;
    }
}

.linkicon {
    color: inherit;
    &:hover {
        color: var(--ion-color-primary);
    }
}

.phone {
    text-decoration: none;
}

#WhyBuy {
    margin: 10px;
    .block {
        padding: 10px 15px 15px;
        label {
            font-size: 0.7em;
            text-transform: uppercase;
            margin-bottom: 15px;
            display: inline-block;
            opacity: 0.5;
        }
        p {
            margin: 0 0 10px;
        }
    }
}

#Dealership {
    transition: all 0.7s ease-in;
    .item-photo {
        background-color: var(--ion-color-light-contrast);
        margin: 0;
        height: 15vh;
        width: 100%;
        overflow: hidden;

        @include short() {
            height: 10vh;
        }

        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    &.tablet .item-photo {
        height: 23vh;
    }

    .store-name {
        font-family: var(--theme-bold-font);
        text-align: center;
        display: block;
        font-size: 20px;
        margin: 5px 0;
    }

    #StoreInfo,
    #StoreHours,
    #Socials {
        padding: 15px 18px;
        font-weight: 300;
        position: relative;
        margin: 0 10px;
    }

    #StoreInfo {
        @include short() {
            font-size: 1em;
        }
        .flexblock {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    #StoreHours {
        margin: 10px;
        h3 {
            font-size: 1.1em;
            @include short() {
                font-size: 1em;
            }
            margin: 0;
            .sub {
                display: block;
                font-size: 0.8em;
            }
        }
        .day {
            gap: 10px 20px;
            margin-bottom: 2px;
            white-space: nowrap;
            font-size: 1em;

            @include short() {
                font-size: 0.8em;
            }

            .time {
                width: 105px;
            }
        }
        .green {
            color: var(--ion-color-success);
        }
        .red {
            color: var(--ion-color-danger);
        }
    }

    #Socials {
        width: 80%;
        margin: 0 auto;
    }

    &.desktop {
        .item-photo {
            border-radius: 10px;
            margin: 0;
        }
        #Detail {
            top: 0;
            background: none;
        }
    }

    &.mobile {
        padding-bottom: 50px;
    }
}

#DealerSimple {
    display: flex;
    flex-direction: column;
    .vinfo-map {
        flex: 1;
    }
    .map-tools {
        position: absolute;
        right: 0;
        max-width: 400px;
        .store-info {
            flex: 1;
            .store-logo {
                width: 30%;
                margin: 0 auto;
                text-align: center;
                display: block;
                margin-bottom: 15px;
            }
            .store-photo {
                clip: rect(25px, 100px, 100px, 25px);
            }
            .cta {
                padding: 5px;
                margin-top: 15px;
                .block.btn {
                    display: block;
                    width: 100%;
                    padding: 15px 0;
                }
            }
        }
    }
}

#DirectionsBtn {
    color: white;
    font-size: 1.1em;
    ion-icon {
        font-size: 1.8em;
        padding: 0 15px;
        position: relative;
        top: 3.3px;
    }
    .btn-text {
        position: relative;
        top: -4px;
    }
}
