@import "../theme/theme.scss";

@media (min-width: 1025px) {
    ion-modal {
        --border-radius: 10px;
        --max-height: 100vh;
        --height: auto;
    }
    ion-modal.large {
        --width: 50vw;
        --height: auto;
        .swiper .swiper-slide {
            align-items: stretch;
            justify-content: stretch;
            flex-direction: column;
        }
    }
}

ion-modal.desktop {
    --width: 35vw;
    &.small {
        --width: 20vw;
    }
    &.large {
        --width: 60vw;
    }
    &.xlarge {
        --width: 80vw;
    }
}

.mobile .modal-content {
    height: 100%;
    max-height: 100vh;
}

.modal-content {
    max-height: 88vh;
    overflow: auto;
    .tools {
        position: absolute;
        top: 0;
        right: 0;
        padding: 14px;
        z-index: 29292;
        .icon-large {
            background-color: rgba(var(--ion-color-light-rgb), 0.4);
            border-radius: 50%;
            float: right;
            cursor: pointer;
        }
    }
    .title {
        background-color: rgba(var(--ion-color-light-rgb), 0.4);
        position: relative;
        top: -14px;
        left: -14px;
        padding: 10px 18px 10px 23px;
        border-radius: 0 0 20px 0;
    }
    .content {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--backdrop-color);
    opacity: 0.7;
    z-index: 12;
}

.no-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--backdrop-color);
    opacity: 0.2;
    z-index: 12;
}

.cta-block {
    position: absolute;
    bottom: 0;
    width: 100vw;
    margin: 0 auto;
    padding: 15px 15px 30px;
    background-color: rgba(var(--ion-color-light-rgb), 0.3);
    border-top: 5px solid rgba(var(--ion-color-primary-rgb), 0.5);
    .ctactions {
        display: inline-block;
        margin-right: 10px;
    }
    &.hidden {
        background-color: transparent;
        border: 0;
        &.block.shaded {
            background-color: transparent;
        }
        #CTA,
        ion-icon.close {
            display: none;
        }
    }
    .flexblock.contain {
        width: 86%;
    }
    .vinfo-tools {
        margin: 8px 0;
        width: 100%;
        justify-content: flex-end !important;
    }
    .btn {
        padding: 10px 34px 10px 56px;
        font-size: 1.1em;
        position: relative;
        cursor: pointer;
        ion-icon {
            font-size: 1.8em;
            position: absolute;
            left: 10px;
            top: 6px;
        }
        &.primary {
            background-color: var(--ion-color-primary);
            &:hover {
                background-color: rgba(var(--ion-color-primary-rgb), 0.5);
            }
        }
        &.secondary {
            background-color: var(--ion-color-secondary);
            &:hover {
                background-color: rgba(var(--ion-color-secondary-rgb), 0.5);
            }
        }
        &.tertiary {
            background-color: var(--ion-color-tertiary);
            &:hover {
                background-color: rgba(var(--ion-color-tertiary-rgb), 0.5);
            }
        }
    }
    .vinfo-logo {
        align-self: center;
        height: 50px;
    }
}

.fineprint {
    font-size: 12px;
    position: absolute;
    bottom: -24px;
    right: 7%;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.carousel {
    position: relative;
    min-height: 470px;
    @include shortdesktop() {
        min-height: 270px;
        height: 40vh;
    }
    &.max {
        position: absolute;
        top: 32px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 12;
        height: 80vh;
        width: calc(100% - 70px);
        max-width: var(--theme-content-width);
        padding: 23px;
        margin: 0 38px;
        animation: fadeIn 1s;
        .item {
            cursor: default;
            width: 100%;
            height: 68vh;
            background-color: var(--ion-background-color);
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            @include shortdesktop() {
                height: 58vh;
            }
            img {
                margin: 0 auto;
                object-fit: contain;
                height: 100%;
                &::part(image) {
                    min-height: 470px;
                    object-fit: contain;
                    height: 100%;
                    @include shortdesktop() {
                        min-height: 370px;
                    }
                }
            }
        }
        .slide {
            border-radius: 0;
        }
        .item-slides {
            width: 100%;
            max-height: 95px;
            .wrap {
                padding: 5px;
                display: flex;
                justify-content: center;
                position: relative;
                img {
                    display: inline-block;
                    height: 79px;
                    margin-right: 10px;
                    cursor: pointer;
                }
                .slide {
                    border-radius: 15px;
                    max-width: 100px;
                    object-fit: cover;
                    &.active {
                        border: 4px solid var(--ion-color-primary);
                    }
                }
                .slide-btn {
                    height: 80px;
                    width: 100px;
                    margin-right: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.8em;
                    text-align: center;
                    gap: 5px;
                    &.active {
                        border: 2px solid var(--ion-color-primary);
                    }
                    ion-img {
                        height: 25px;
                        margin: 0 auto;
                    }
                    .doc-name {
                        line-height: 14px;
                    }
                }
                .close-icon {
                    position: absolute;
                    bottom: -11px;
                    right: -35px;
                    ion-icon {
                        font-size: 44px !important;
                        cursor: pointer;
                    }
                    &:hover {
                        ion-icon {
                            color: var(--ion-color-primary);
                        }
                    }
                }
            }
        }
        .prev {
            left: -33px;
        }
        .next,
        .prev {
            font-size: 4em;
            top: 48%;
            background-color: transparent;
        }
    }
    .item {
        cursor: pointer;
        min-height: 470px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 20px;
        @include shortdesktop() {
            min-height: 270px;
            height: 40vh;
        }
        ion-img {
            @include shortdesktop() {
                min-height: 270px;
            }
            &::part(image) {
                min-height: 470px;
                object-fit: cover;
                @include shortdesktop() {
                    min-height: 270px;
                    height: 40vh;
                }
            }
        }
    }
    .slide {
        display: none;
        border-radius: 15px;
        overflow: hidden;
        transition: width 1s ease-out;
        &.active {
            display: inline-block;
            &.white-bg {
                background-color: white;
                cursor: default;
            }
        }
    }
    .prev,
    .next {
        position: absolute;
        top: 45%;
        background-color: var(--ion-background-color);
        z-index: 11;
        font-size: 2em;
        height: 57px;
        width: 17px;
        cursor: pointer;
        &:hover {
            color: var(--ion-color-secondary);
        }
    }
    .prev {
        left: 0;
        border-radius: 0 12px 12px 0;
        padding: 10px 0 0;
        ion-icon {
            position: relative;
            left: -19px;
        }
    }
    .next {
        right: 0;
        border-radius: 12px 0 0 12px;
        padding: 10px 0 0;
        ion-icon {
            position: relative;
            right: -4px;
        }
    }

    .bullets {
        position: absolute;
        bottom: -7px;
        z-index: 11;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        display: flex;

        .wrap {
            height: 25px;
            background-color: var(--ion-background-color);
            margin: 0 auto;
            border-radius: 12px 12px 0 0;
            padding: 3px 0px 0 10px;
            .bullet {
                padding: 5px;
                margin-right: 10px;
                height: 10px;
                width: 10px;
                background-color: var(--ion-color-dark);
                display: inline-block;
                border-radius: 70px;
                border: 1px solid var(--ion-color-dark);
                cursor: pointer;
                &:last-child {
                    margin-right: 10px;
                }
                &.active,
                &:hover {
                    border: 1px solid var(--ion-color-secondary);
                    background-color: var(--ion-color-secondary);
                }
            }
        }
    }
}

.max .blt-nav {
    font-size: 2.5em;
    top: 20px;
}

.blt-nav {
    position: relative;
    top: 2px;
    left: -5px;
    cursor: pointer;
    &:hover {
        color: var(--ion-color-secondary);
    }
}

.vinfo-divider {
    width: 100%;
    display: table;
    white-space: nowrap;
    height: auto;
    margin: "";
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    strong {
        font-family: var(--theme-bold-font);
        &.small {
            font-size: 0.9em;
            font-weight: 500;
        }
        &.large {
            font-size: 1.1em;
        }
    }

    &::before {
        background-position: right 1em top 50%;
    }

    &::after {
        background-position: left 1em top 50%;
    }

    &::after,
    &::before {
        content: "";
        top: 50%;
        width: 50%;
        display: table-cell;
        position: relative;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
    }
}

$popupColor: rgba(var(--backdrop-color-rgb), 0.7);
.vinfo-popup {
    position: fixed;
    text-align: center;
    background-color: $popupColor;
    color: white;
    padding: 10px 20px;
    z-index: 13;
    border-radius: 5px;
    max-height: 100px;
    animation: fadeIn 1s;
    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 10px solid transparent;
    }
    &.lft:after {
        top: calc(50% - 10px);
        left: 100%;
        border-left: 10px solid $popupColor;
    }
    &.rgt:after {
        top: calc(50% - 10px);
        right: 100%;
        border-right: 10px solid $popupColor;
    }
    &.btm:after {
        top: -20px;
        left: calc(50% - 10px);
        border-bottom: 10px solid $popupColor;
    }
    &.top:after {
        top: 100%;
        left: calc(50% - 10px);
        border-top: 10px solid $popupColor;
    }
}

#fourofour {
    height: 100%;
    width: 100%;
    padding-bottom: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    ion-card {
        width: 50vw;
    }
    ion-icon {
        font-size: 8em;
    }
}
