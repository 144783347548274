@import "./variables.scss";

.slides {
    position: relative;
    height: 100%;
    flex: 1 1 auto;
    .slide {
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        transition: transform 1s ease;
        z-index: 10;
        &.left {
            opacity: 0;
            animation: fadeOut 1s;
            transform: translateX(-100vw);
            -webkit-transform: translateX(-100vw);
            left: 0;
        }

        &.right {
            opacity: 0;
            animation: fadeOut 1s;
            transform: translateX(100vw);
            -webkit-transform: translateX(100vw);
            right: 0;
        }

        &.center {
            opacity: 1;
            animation: fadeIn 1s;
            transform: translateX(0);
            -webkit-transform: translateX(0);
        }
    }
    .fade {
        width: 100%;
        transition: transform 1s ease;
        &.left {
            display: none;
            opacity: 0;
            animation: fadeOut 1s;
        }

        &.right {
            display: none;
            opacity: 0;
            animation: fadeOut 1s;
        }

        &.center {
            display: block;
            opacity: 1;
            animation: fadeIn 1s;
        }
    }
}

@keyframes rightCenter {
    from {
        left: 60vw;
        right: -10px;
    }
    to {
        left: $centerPos;
        right: $centerPos;
    }
}
@keyframes centerLeft {
    from {
        left: $centerPos;
    }
    to {
        left: $leftPos;
    }
}
@keyframes centerRight {
    from {
        right: $centerPos;
    }
    to {
        right: $rightPos;
    }
}
@keyframes leftCenter {
    from {
        left: -100px;
    }
    to {
        left: $centerPos;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
