@import "../../../../theme/mixins.scss";

#Menu {
    height: 135px;
    background-color: var(--ion-background-color, #fff);
    z-index: 9999;
    position: relative;
    .positioned {
        position: relative;
        height: 100%;
        width: 100%;
    }
    .item {
        padding: 0 23px;
        font-size: 0.9em;
        white-space: nowrap;
        text-align: center;
        .sect-text {
            animation: fadeIn 3s;
        }
    }

    &.mobile,
    &.tablet {
        .item {
            height: 78px;
            position: absolute;
            bottom: 0;
            transition: height 0.7s ease-out;
            &.left {
                left: 0;
            }
            &.center {
                left: 33.3%;
                right: 33.3%;
                margin: 0 auto;
            }
            &.right {
                right: 0;
            }
            .menu-icon {
                transition: all 0.7s ease-out;
                margin: 3px auto;
                height: 36px;
                width: 36px;
                border-radius: 50%;
                fill: currentColor;
            }
            &.active {
                width: 100px;
                height: 100px;
                padding: 0;
                transition: height 0.7s ease-in;
                .roundBg {
                    position: absolute;
                    top: -10px;
                    left: 0;
                    right: 0;
                    height: 100px;
                    width: 100px;
                    margin: 0 auto;
                    border: 14px solid var(--ion-background-color, #fff);
                    border-radius: 50%;
                    z-index: 1;
                }
                .menu-icon {
                    transition: all 0.7s ease-in;
                    height: 75px;
                    width: 75px;
                    background-color: black;
                    -webkit-box-shadow: 0px 0px 21px -3px var(--ion-color-secondary);
                    box-shadow: 0px 0px 21px -3px var(--ion-color-secondary);
                    padding: 11px;
                    z-index: 2;
                    position: relative;
                }
            }
        }
    }
    &.mobile {
        height: 100px;
        .item {
            height: 70px;
            .menu-icon {
                height: 30px;
                width: 30px;
            }
            &.active {
                width: 83px;
                height: 83px;
                .roundBg {
                    height: 80px;
                    width: 80px;
                }
                .menu-icon {
                    height: 65px;
                    width: 65px;
                }
            }
        }
    }
    &.desktop {
        @include flexbox();
        .item {
            height: 78px;
            width: 33%;
            display: inline;
            &.active {
                background-color: orange;
            }
        }
    }
}
