@import "../../../../theme/mixins.scss";

#SalespersonTile {
    padding: 10px;
    width: 44%;
    height: 72px;
    align-self: center;
}

#SalespersonMessage.fix-btm {
    position: fixed;
    left: 0px;
    bottom: 100px;
    width: 100vw;
    max-height: 70vh;
    flex-direction: column-reverse;
    z-index: 99;
    padding: 0 15px;
    ion-avatar {
        width: 50px;
        height: 50px;
        animation: fadeInUp 200ms linear 1 both;
    }
    .comment,
    .comment .message {
        max-height: 70vh;
        min-height: 50px;
    }
    .comment {
        margin: 0 0 10px 0;
        box-shadow: 5px -6px 0px -1px var(--ion-color-medium);
        .speak {
            transform: rotate(89.1deg);
            bottom: -6px;
            right: 5px;
            left: auto;
        }
    }
    #CustomerMessageText {
        height: auto;
        min-height: 50px;
        font-size: 1.1em;
    }
    .comment-block {
        flex-direction: column-reverse;
    }
}

.tablet #SalespersonMessage.fix-btm {
    bottom: 140px;
    padding: 0 25px;
    width: 70vw;
    right: 0;
    left: auto;
    font-size: 1.2em;
    #CustomerMessageText {
        font-size: 1.2em;
    }
}

#SalespersonMessage {
    position: absolute;
    bottom: 24px;
    left: 15%;
    align-items: flex-end;

    @include smdesktop() {
        left: 11.8%;
    }

    @include tinydesktop() {
        left: 6%;
    }

    .comment-block {
        width: 100%;
        ion-icon.close {
            position: absolute;
            top: 7px;
            right: 7px;
            font-size: 1.5em;
            cursor: pointer;
            z-index: 99;
        }
    }

    #CustomerMessageText {
        height: 90px;
        display: block;
    }

    #seemore {
        color: var(--ion-color-primary);
        animation: colorchange 2s alternate;
        text-decoration: underline;
    }

    &.full-size {
        width: 54%;
        max-height: 70vh;
        animation: fadeInUp 200ms linear 1 both;
        z-index: 99;
        bottom: 125%;
        .comment,
        .comment .message {
            font-size: 1.2em;
            max-height: 500px;
            cursor: default;
        }
        .comment {
            padding: 20px;
            #CustomerMessageText {
                height: auto;
            }
        }

        ion-avatar {
            width: 100px;
            height: 100px;
            animation: fadeInUp 200ms linear 1 both;
        }
    }

    &.compact {
        height: 121px;
        width: 30%;
        @include smdesktop() {
            width: 35%;
        }
        #CustomerMessageText {
            height: 49px;
        }
    }

    ion-avatar {
        width: 70px;
        height: 70px;
        margin-right: 5px;
        margin-top: 5px;
    }

    .creds {
        margin: 5px 15px;
        .name {
            font-family: var(--theme-bold-font);
            font-size: 0.9em;
        }
        .job {
            display: block;
            font-size: 0.7em;
        }
    }

    .comment {
        max-height: 103px;
        width: 100%;
        margin: 10px;
        font-size: 1em;
        border-radius: 8px;
        position: relative;
        background-color: var(--ion-color-step-200);
        -webkit-box-shadow: 5px 5px 0px -1px var(--ion-color-medium);
        box-shadow: 5px 5px 0px -1px var(--ion-color-medium);
        z-index: 9;
        padding: 10px;
        cursor: pointer;
        &.blownup {
            max-height: 300px;
        }
        .message {
            padding: 5px 20px 5px 5px;
            overflow: auto;
            max-height: 84px;
            width: 100%;
        }
        .says {
            font-size: 0.8em;
            display: block;
            margin-top: 14px;
        }
        .speak {
            position: absolute;
            bottom: 1px;
            width: 0;
            height: 0;
            left: -8px;
            border: 0.75rem solid transparent;
            border-top: none;
            border-bottom-color: var(--ion-color-step-200);
            transform: rotate(4deg);
        }
    }
}

#Salesperson {
    transition: all 0.7s ease-in;
    padding-bottom: 15px;
    .item-photo {
        background-color: var(--ion-color-light-contrast);
        margin: 0;
        height: 15vh;
        width: 100%;
        overflow: hidden;
        @include short() {
            height: 10vh;
        }
        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    &.tablet .item-photo {
        height: 23vh;
    }

    #SalesInfo {
        position: relative;
        top: -75px;
        text-align: center;
        margin-bottom: -70px;
        ion-avatar {
            width: 130px;
            height: 130px;
            margin: 0 auto;
            border: 3px solid var(--ion-background-color);
        }
        h2 {
            font-size: 1.2em;
            margin: 10px 0 5px;
            font-family: var(--theme-bold-font);
        }
        h3 {
            font-size: 1em;
            font-weight: 100;
            margin-top: 0;
        }
    }

    #SalesContact,
    #SalesBio {
        padding: 15px 18px;
        font-weight: 300;
        position: relative;
        margin: 0 10px;
    }

    #SalesBio {
        margin-top: 10px;
        margin-bottom: 25px;
    }
}

@keyframes colorchange {
    0% {
        color: var(--ion-text-color);
    }

    100% {
        color: var(--ion-color-primary);
    }
}
