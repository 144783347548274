@import "./mixins.scss";

body {
	color: var(--ion-text-color);
}

.fancy-btn {
	cursor: pointer;
	text-align: center;
	background-color: var(--ion-color-step-200);
	color: white;
	border-radius: 10px;
	margin: 0 auto;
	width: 80%;
	padding: 5px 20px;
	span {
		background-color: var(--ion-color-step-100);
		display: block;
		padding: 15px;
		border-radius: 15px;
		color: var(--ion-color-success);
		font-weight: 700;
		font-size: 1.1em;
		&:hover,
		&:active {
			background-color: var(--ion-color-success);
			color: var(--ion-color-step-100);
		}
	}
}

.grad-btn {
	--x: 50%;
	--y: 50%;

	&:hover {
		background: radial-gradient(
			farthest-corner at var(--x) var(--y),
			var(--ion-color-primary),
			var(--ion-color-secondary)
		) !important;
	}
}

.info-btn {
	height: 100%;
	width: 100%;
	text-align: left;
	font-size: 1em;
	span {
		position: relative;
		top: 16px;
		width: 68%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
	}
}

.block {
	&.space {
		padding: 10px 10px 0;
		&.more {
			padding: 20px 20px 0;
		}
	}
	&.rounded {
		border-radius: 15px;
	}
	&.shaded {
		background: var(--ion-color-light);
		&.btn {
			cursor: pointer;
			background-color: var(--ion-color-step-200);
			color: inherit;
			text-decoration: none;
			&:hover {
				background-color: var(--ion-color-step-100);
			}
			.icon {
				width: 38px;
				float: left;
				position: relative;
				bottom: -7px;
				left: 11px;
				margin-right: 14px;
			}
		}
		&.action:hover {
			cursor: pointer;
			background-color: var(--ion-color-step-100);
		}
		&.darker {
			background: var(--ion-color-medium);
		}
		&.darkest {
			background: var(--ion-color-dark);
		}
		&.transparent {
			background-color: rgba(var(--ion-color-light-rgb), 0.8);
		}
	}
	&.card {
		margin-bottom: 10px;
	}
	&.tablet.container {
		padding: 20px 60px;
	}
}

.flexblock {
	@include flexbox();
	&.rows {
		&.reverse {
			flex-direction: row-reverse;
		}
	}
	&.cols {
		height: 100%;
		flex-direction: column;
		&.reverse {
			flex-direction: column-reverse;
		}
	}
	&.wrap {
		flex-wrap: wrap;
		&.reverse {
			flex-wrap: wrap-reverse;
		}
	}
	&.center {
		justify-content: center;
	}
	&.stretch {
		justify-content: space-between;
		&.even {
			justify-content: space-evenly;
		}
	}
	&.two-col {
		.block {
			@include flex(1 0 44%);
		}
	}
	&.three-col {
		.block {
			@include flex(1 0 33%);
		}
		&.gap-ten {
			.block {
				@include flex(1 0 30%);
			}
		}
		&.gap-thirty {
			.block {
				@include flex(1 0 29%);
			}
		}
	}
	&.aln-start {
		align-items: flex-start;
		align-self: flex-start;
	}
	&.aln-cnt {
		align-items: center;
	}
	&.aln-btm {
		align-items: flex-end;
	}
	&.aln-end {
		justify-content: flex-end;
	}
	&.gap-ten {
		gap: 10px;
	}
	&.gap-thirty {
		gap: 30px;
	}
	&.gap-hundo {
		gap: 100px;
		@include smdesktop() {
			gap: 50px;
		}
	}
	&.fill {
		height: 100%;
	}
	&.pad {
		padding: 5px;
	}
	&.pad-ten {
		padding: 10px;
	}
	.grow {
		@include flex(2 0px);
		flex-grow: 1;
	}
}

.mobile {
	.flexblock {
		&.two-col {
			&.rows {
				flex-direction: column;
			}
			.block {
				@include flex(1 0 100%);
			}
		}
		&.three-col {
			&.rows {
				flex-direction: column;
			}
			.block {
				@include flex(1 0 100%);
			}
		}
	}
}

.green {
	color: var(--ion-color-success);
}
.red {
	color: var(--ion-color-danger);
}

// @media (max-height: 480px) and (min-width: 480px) and (max-width: 900px) {
// 	html {
// 		-webkit-transform: rotate(-90deg);
// 		-moz-transform: rotate(-90deg);
// 		-ms-transform: rotate(-90deg);
// 		-o-transform: rotate(-90deg);
// 		transform: rotate(-90deg);
// 		-webkit-transform-origin: left top;
// 		-moz-transform-origin: left top;
// 		-ms-transform-origin: left top;
// 		-o-transform-origin: left top;
// 		transform-origin: left top;
// 		width: 100vh;
// 		height: 100vw;
// 		position: absolute;
// 		top: 100%;
// 		left: 0;
// 	}
// }
