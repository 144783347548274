$typing-bullet-size: 10px;
$typing-bullet-color: var(--ion-text-color);
$typing-speed: 800ms;
$typing-bullet-delay: 150ms;

.typing {
    $this: typing;

    padding: 20px;
    font-size: 0;
    animation: fadeInUp 200ms linear 1 both;

    &__bullet {
        display: inline-block;
        width: $typing-bullet-size;
        height: $typing-bullet-size;
        border-radius: 50%;
        background-color: fade($typing-bullet-color, 30%);
        transition: all ($typing-speed/2) linear;

        &:not(:last-child) {
            margin-right: 3px;
        }
    }

    &.active {
        .typing__bullet {
            background-color: $typing-bullet-color;
            animation: bounce $typing-speed linear infinite both;

            &:nth-child(2) {
                animation-delay: $typing-bullet-delay;
            }

            &:nth-child(3) {
                animation-delay: $typing-bullet-delay * 2;
            }
        }
    }
}

/**
 * Animations
 */
@keyframes bounce {
    0%,
    80%,
    100% {
        opacity: 1;
        transform: translateY(0);
    }

    50% {
        opacity: 0.5;
        transform: translateY(-100%);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
